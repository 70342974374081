<!-- 空数据状态的插槽 -->
<template>
    <div class="no_date_box">
        <!-- <div class="no_img">
            <img src="../assets/img/search/no_data_img.png" />
        </div> -->
        <h3 class=text-title>Your search - ""{{ $route.query.searchname }}"" - did not match any information</h3>
        <h2 class="title">Suggestions:</h2>
    <ul>
        <li class="ul-title" v-for="(item,index) in lilist " :key=index>{{item}}</li>
    </ul>
        <!-- <div class="des_one">Sorry, no relevant enterprises found</div>
        <div class="des_two">Please enter more accurate keywords and search again;<br/>
        Replace filter conditions and search again.</div>
        <div class="des_one">Or click here for in-depth search</div> -->
      


         
    </div>
    
</template>
<script>
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    data() {
        return {
            tabCountryList: [],
            countryName: '', //选中国家
            countryImg: '', //选中国家的国旗
            tabActiveName: '', //选择的区域
            productChose: '',
            productOptions: [],
            show: 0,
            optionsProps: {
                label: 'nameEn', //名称展示
                value: 'displayCode', //值展示
                // checkStrictly: true,     //是否严格的遵守父子节点不互相关联
                expandTrigger: 'click', //次级菜单的展开方式
                emitPath: false,
            },
            countryCode: '',
            property: 'value',
            lilist:[
                'Make sure all words are spelled correctly.',
                'Try different keywords.',


        ]
        };
    },
    created() {
    
    },
    
    methods:{

    }
}
</script>
<style scoped>
.no_date_box{
    /* width: 100%;
ma
    margin: 10vh 15vh; */
    margin-bottom: 200px;
  
}
.no_img{
    width: 250px;
    height: 197px;
    margin: 0 auto;
    margin-bottom: 4px;
}
.no_img img{
    width: 100%;
    height: auto;
}
.des_one{
    line-height: 32px;
    font-size: 26px;
    font-family: "Arial Bold";
    color: #1290C9;
    margin-bottom: 10px;
}
.des_two{
    font-size: 12px;
    color: #022955;
    line-height: 24px;
}
.el-button {
    background:#1290C9 !important;
    color: white;
    /* padding: 15px 30px; */
    font-size: 16px;
    height: 40px;
    width: 140px;
    border-radius: 10px;
}
/* .el-table__empty-block{
    text-align: left !important;
} */
.text-title{
    color: #022955;
    font-weight: 500;
    font-size: 14px;
  
}
.title{
   color: #1290c9;
   height: 40px;
    line-height: 40px;
    font-size: 16px;
    
}

.ul-title{
    color: #022955;
    font-weight: 500;
    height: 35px;
   line-height: 35px;
   list-style: disc;
   margin-left: 15px;
   padding-left: 10px;
   font-size: 14px;
}
ul li::marker{
    color: #1290c9;
        }
        .search_box {
    width: 350px;
    height: 120px;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    /* margin: auto; */
    padding: 25px 30px;
}
.search {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #dddddd;
    color: #022955;
}
.search_right {
    flex: 1;
    position: relative;
}
/* 选择下拉 */
.pro_cas,
.search_left {
    width: 180px;
    height: 150%;
    position: relative;
}
.search_left::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    width: 1px;
    height: 20px;
    background-color: #dddddd;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-input__inner {
    border: none;
    font-size: 16px;
}
.country_flag {
    display: flex;
    align-items: center;
}
.country_flag_img {
    width: 36px;
    height: 22px;
    margin: 22px 6px 24px 20px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.sel_value {
    width: 74px;
    font-size: 16px;
    word-break: break-all;
}
.pro_cas,
.search_left .el-select {
    position: absolute;
    top: 0;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-select .el-input__inner {
    background-color: transparent;
    /* height: 68px !important; */
    /* opacity: 0; */
    padding-left: 18px;
}
.search_left.on /deep/ .el-select .el-input__inner {
    padding-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
    color: #022955;
}
.sel_place {
    width: 100%;
    line-height: 68px;
    text-align: center;
    display: inline-block;
}
.el-select-dropdown__wrap {
    max-height: 375px !important;
}
.el-select-dropdown__list {
    padding: 10px 0 !important;
}
.search_sel_container {
    width: 1200px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
    width: 240px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background-color: #eee;
}
.search_sel_container /deep/ .el-tabs__active-bar {
    display: none;
}
.search_sel_container .right {
    width: 959px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 340px;
    align-content: flex-start;
    margin-right: 1px;
    padding: 0 12px;
    overflow: auto;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
    height: 42px;
    line-height: 42px;
    margin-bottom: 8px;
        padding: 0 10px !important;
}
.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
    background: rgba(18, 144, 201, 0.1);
}
.el-select-dropdown__item:hover {
    background: rgba(18, 144, 201, 0.1);
}

.search_sel_container /deep/ .el-tabs__item:hover {
    color: #1290c9;
    font-weight: normal;
}
.search_sel_container .left_txt {
    font-size: 16px;
}
.search_sel_container .right_txt {
    font-size: 12px;
    color: #8497ab;
}
.search_sel_container .right .el-select-dropdown__item {
    width: 222px;
    height: 56px;
    padding: 0 10px;
    margin: 10px 5px;
    border: 1px solid #fff;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
    background: rgba(18, 144, 201, 0.1);
    border-radius: 14px;
    border: 1px solid #1290c9;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
    color: #022955;
    font-weight: normal;
}
.right_item {
    height: 100%;
    width: 100%;
}
.search_sel_container .right .img {
    width: 54px;
    height: 100%;
    margin-right: 5px;
}
.search_sel_container .right .img img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.search_sel_container .right .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search_sel_container .right .rBox .coun_title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search_sel_container .right .rBox .coun_num {
    font-size: 12px;
    line-height: 1;
    color: #8497ab;
}
.search_option /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: none;
}
.flex_center{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 1600px) {
    .line {
        bottom: -46px;
    }
    .foot_box::before {
        width: 1390px;
        background-size: 100%;
    }
}
</style>