<template>
    <div class="right_box phone_page_bg" style="margin-top: 40px !important">
        <div class="phone_right_cont_box line_2 phone_page_bg" style="margin-bottom: 10px !important">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
        <div class="">
            <div class="table_box">
                <ul class="phone_table_box">
                    <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                        <div class="phone_table_box_item_right">
                            <h1 class="line_1" v-if="!showTranslate" style="width: 98%; color: #1290c9" @click="setid(item)">
                                {{ item.title }}
                            </h1>
                            <h1 class="line_1" v-if="showTranslate" style="width: 98%; color: #1290c9" @click="setid(item)">
                                {{ item.titleEn }}
                            </h1>
                            <el-tooltip class="item" effect="dark" :content="showTranslate == true ? item.source : item.sourceEn" placement="top">
                                <h2 v-if="!showTranslate" class="line_1">
                                    Source:
                                    {{ item.source }}
                                </h2>
                                <h2 v-if="showTranslate" class="line_1">
                                    Source:
                                    {{ item.sourceEn }}
                                </h2>
                            </el-tooltip>

                            <h2>
                                <div style="width: 160px" class="line_1">
                                    Date:
                                    {{ item.publishTime }}
                                </div>
                            </h2>
                        </div>
                    </li>
                </ul>
            </div>
            
        </div>
    </div>
</template>
<script>
import { getPdfLink } from '@/api/searchApi.js';
import crypto from '@/utils/crypto';

export default {
    props: {
        parentTable: {
            type: Array,
            default: function () {},
        },
        page: {
            type: Object,
            default: function () {},
        },
        searchName: {
            type: String,
            default: '',
        },
        showTranslate: {
            type: Boolean,
            default: true,
        },
        powerResult: {
            type: Object,
            default: function () {},
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    methods: {
     
        setid(row) {
            let power = this.powerResult.list[5].power;
            if (power) {
                if (row.link == undefined) {
                    getPdfLink({ articleRef: row.id }).then(res => {
                        let res1 = JSON.parse(crypto.decrypt(res));
                        if (res1.data.includes('http')) {
                            let routeData = res1.data;
                            window.open(routeData, '_blank');
                            if (window.innerWidth < 720) {
                                window.location.href = routeData;
                            }
                        } else {
                            let routeData = 'http://' + res1.data;
                            window.open(routeData, '_blank');
                            if (window.innerWidth < 720) {
                                window.location.href = routeData;
                            }
                        }
                        // let routeData = res1.data;
                        // window.open(routeData, '_blank');
                    });
                }
                if (row.link.includes('http')) {
                    let routeData = row.link;
                    window.open(routeData, '_blank');
                    if (window.innerWidth < 720) {
                        window.location.href = routeData;
                    }
                } else {
                    let routeData = 'http://' + row.link;
                    window.open(routeData, '_blank');
                    if (window.innerWidth < 720) {
                        window.location.href = routeData;
                    }
                }
                // let routeData = row.url;
                // window.open(routeData, '_blank');
            } else {
                this.message();
            }
        },
    },
};
</script>
<style scoped>
@media (max-width: 821px) {
    .phone_right_cont_box {
        display: block !important;
    }
    .computer_right_cont_box {
        display: none;
    }
    .phone_right_cont_box {
        width: 100%;
        /* padding: 15px; */
        padding: 0px 20px;
        /* box-shadow: 0px 2px 6px 1px rgba(10,33,57,0.14); */
        background: #ffffff;
    }
    .phone_table_box {
    }
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 28px;
        height: 17px;
        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .line_1 {
        white-space: nowrap !important;
    }
}
</style>
