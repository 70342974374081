<template>
    <div>
      <!-- add to List -->
      <el-dialog  :visible.sync="addDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normal">
        <div  class="right_cont_box">
                <div class="table_box">

                    <el-table ref="treeTable" :data="parentTable" border style="width: 100%">
                   

                        <el-table-column label="Company Name" align="left"  show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                    <img style="height: 22px;width: 32px; margin-right: 5px;" :src="scope.row.flag" />
                                    {{ scope.row.name }}
                                </div>
                                <div v-show="!showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                    <img style="height: 22px;width: 32px; margin-right: 5px;" :src="scope.row.flag" />
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="Branch" prop="branch" align="center" show-overflow-tooltip :resizable="false" /> -->
                        <el-table-column label="Location" width="220" prop="registered_address_in_full,countrycode" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" class="line_1">
                                    {{ scope.row.registered_address_in_full }}
                                    <!-- <i v-if="scope.row.countrycode != ' ' && scope.row.countrycode != '' && scope.row.city_en != '' && scope.row.city_en != ' '">,</i> -->
                                   ( {{ scope.row.countrycode}})
                                </div>
                                <div v-show="!showTranslate" class="line_1">
                                    {{ scope.row.registered_address_in_full }}
                                    <!-- <i v-if="scope.row.countrycode != ' ' && scope.row.countrycode != '' && scope.row.city_cn != '' && scope.row.city_cn != ' '">,</i> -->
                                    ({{ scope.row.countrycode }})
                                </div>
                            </template>
                        </el-table-column>
                      
                        <el-table-column label="Date" width="180" prop="incorporation_date" align="center" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Type" width="180" :prop="showTranslate == false ? 'company_type' : 'company_type'" align="center" show-overflow-tooltip :resizable="false" />

                        <el-table-column label="Status" width="80" :prop="showTranslate == false ? 'current_status' : 'current_status'" align="centers" show-overflow-tooltip :resizable="false" />
                        <template slot="empty">
                            <noDate />
                        </template>
                      </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{$route.query.searchname}}"</div>
                    </div>
                </div>
            </div>
      </el-dialog>
  
      <!-- create to List -->

    </div>
  </template>
  <script>

import noDate from '@/components/newtabledata';
import Page from '@/components/page';
import { mixins1 } from '@/mixins/index';
  export default {
    mixins: [mixins1],
    components: { Page,noDate},
    props: {
      addList: {
        type: Array,
      },
      addPop: {
        type: Boolean,
        default: false,
      },
      collecObj: {
        type: Object,
      },
      fromPage: {
        type: String,
      },
    //   seachName:{
    //     type: String,
    //   },
    //   searchCode:{
    //     type: String,
    //   }

    },
    data() {
      return {
        page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
        // addList:[],
        parentTable:[],
    
        loadig:true,
        showTranslate:true,
        createDialog: false, //新增分组弹框
    
      }
    },
    computed: {
        
      addDialog: {
        
        get() {
          return this.addPop
        },
        set(newValue) {
        //   this.$emit('update:addPop', newValue)
        },
      },
    },
    watch: {

    },
    beforeDestroy() {
        this.$EventBus.$off('deepseach')
    },
    
    mounted(){
        
       let that =this
      
      this.$EventBus.$on('deepseach',(item)=>{
        console.log(`item`, item);
           })
  
    },

    methods: {
        onPageChangeList(pageNo) {
            console.log(pageNo);
            this.page.pageNo = pageNo;
            // this.getAddToList();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            // this.getAddToList();
        },
   
        
    //     getAddToList(country){
    //         let searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
    //         let searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;

    //         console.log(this.$route.query);
    //         let params ={
    //             company_name:searchName,
    //             company_country:country,
    //             page:this.page.pageNo,
    //             pageSize:this.page.pageSize
    //         }
        
    //         searchop(params).then(res=>{
    //             if(res.code){
    //                 this.loadig=false
    //                 let data =[]    
    //                 this.page.total = res.data.results.total_count;
    //                 console.log(` this.page.total`,  this.page.total);
    //     res.data.results.companies.map(item=>{
    //             data.push(item.company)
    //         })
    //    data.map(item=>{
    //     item.countrycode=(item.jurisdiction_code.slice(0,2)).toUpperCase()
    //     let countryImg = this.getCountryImgUrl(item.countrycode);
    //                 let flag = require('../../static' + countryImg);
    //                item.flag = flag;
    //    })
    //    this.parentTable=data
    //    console.log(data);
    //             }else{
    //                 this.parentTable=[]
    //                 this.loadig=false
    //             }

    //         })
         
    //     },
      //添加列表

      //关闭add弹窗
      closeDialog() {
        this.$emit('childCloseDialog2', false)
    
      },
    },
  }
  </script>
  <style scoped>
/deep/ .dialog_normal{
    width: 1100px !important;
}
.table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 44px !important;
}
/* .table_box /deep/ .el-table__empty-block{
    justify-content: flex-start !important;
    display: block;
    text-align: left;
} */
  </style>