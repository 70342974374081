<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu">
            <button class="btn_Retr" @click="RetrievalResult">
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>

            <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()">
                <el-collapse-item name="0">
                    <template slot="title">
                        Sort
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader ref="Sort" filterable clearable placeholder="By Date" :key="cascaderKey" v-model="companyTypeCheckList" @change="menuCheck('')" :options="newslsits" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <span class="casca_lab" style="width: 180px">
                                    <span ref="sty">{{ data.valueEn }}</span>
                                </span>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Date" name="1">
                    <template slot="title">
                        Date
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0].power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader ref="Date" filterable clearable placeholder="Select Date" :filter-method="filterCasindst" v-model="timePeriodCheckList" @change="menuCheck('')" :options="timePeriodList" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <span class="casca_lab">{{ data.valueEn }}</span>
                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Source" name="2" v-if="sourcelist">
                    <template slot="title">
                        Source
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader filterable ref="Country" clearable placeholder="Select Source" :filter-method="filterCasindst" v-model="companyStatusCheckList" :options="sourcelist" @change="menuCheck('')" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                    <span class="casca_lab">{{ data.valueEn }}</span>
                                </el-tooltip>
                                <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>

                <el-collapse-item title="Industry" name="3" v-if="companyLeveList?.length > 0">
                    <template slot="title">
                        Industry
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader ref="Sort" filterable clearable placeholder="Select Industry" :filter-method="filterCasindst" :key="cascaderKey" v-model="companyLeveCheckList" @change="menuCheck('')" :options="companyLeveList" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                    <span class="casca_lab">{{ data.valueEn }}</span>
                                </el-tooltip>
                                <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Subjects" name="4">
                    <template slot="title">
                        Subjects
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader filterable ref="Industry" clearable :filter-method="filterCasindst" v-model="listedCompanyCheckList" :key="cascaderKey" @change="menuCheck('')" :options="listedCompanyList" placeholder="Select Subjects" :props="optionsProps" popper-class="cas_loc_box ">
                            <template slot-scope="{ data }">
                                <span class="casca_lab">{{ data.valueEn }}</span>
                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company" name="5">
                    <template slot="title">
                        Company
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader clearable filterable v-model="companySizeCheckList" :filter-method="filterCasindst" :options="companyNameList" @change="menuCheck('')" placeholder="Select Company" collapse-tags :props="optionsProps" popper-class="cas_loc_box ">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                    <span class="casca_lab">{{ data.valueEn }}</span>
                                </el-tooltip>
                                <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="News Type" name="6">
                    <template slot="title">
                        News Type
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <el-cascader clearable filterable v-model="companyNewsCheckList" :options="companyNewsList" @change="menuCheck('')" placeholder="Select News Type" collapse-tags :props="optionsProps" popper-class="cas_loc_box ">
                            <template slot-scope="{ data }">
                                <span class="casca_lab">{{ data.valueEn }}</span>
                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="right_box">
            <div class="top_condition flex_center_between_box">
                <div class="condition_lab_box "  style="display: flex; align-items: center">
                   <i class="zhankai" @click="applefilters" style="margin-right: 10px"><img src="../../assets/img/guolv.png" alt="" /></i>
                  
                    <div :class="{ condition_labb: true, condition_lab: isActive }" class="computer_right_cont_box" @click="tabshow">
                        <template>
                            <el-tag v-for="tag in countryTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'countryTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in arrProvTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'arrProvTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in industryTag" :key="tag.value + '_industryTag'" @close="handleClose(tag, 'industryTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyTypeTag" :key="tag.value + '_companyTypeTag'" @close="handleClose(tag, 'companyTypeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companySizeTag" :key="tag.value + '_companySizeTag'" @close="handleClose(tag, 'companySizeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyStatusTag" :key="tag.value + '_companyStatusTag'" @close="handleClose(tag, 'companyStatusTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in listedCompanyTag" :key="tag.value + '_listedCompanyTag'" @close="handleClose(tag, 'listedCompanyTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in timeTag" :key="tag.value + '_timeTag'" @close="handleClose(tag, 'timeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyNewsTag" :key="tag.value + 'NewTag'" @close="handleClose(tag, 'NewTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyLeveTag" :key="tag.value + 'LeveTag'" @close="handleClose(tag, 'LeveTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <!-- <span class="clear_lab" v-if="isActive" @click="tabshow()" style="margin-right: 20px ;font-size: 16px; ">......</span> -->
                        <span class="clear_lab" @click="clearAllTags">Clear All</span>
                    </div>
                </div>
                <div class="flex_center">
                    <div class="top_ic_btn no_login" style="cursor: pointer" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div>
                    <!-- <div class="btn_export flex_center" @click="exportResult">
                        <i class="el-icon-lock" style=" font-weight: 800;" v-if="!powerResult?.list[3].deductionPoint"></i>   
                        
                        {{ zhicai }}
                    </div> -->
                </div>
            </div>
            <!-- 电脑端-->
            <div class="right_cont_box computer_right_cont_box">
                <div class="table_box">
                    <el-table ref="treeTable"  :key="refresh" :data="parentTable" border row-key="aaa_id" style="width: 100%" v-loading="loading">
                        <el-table-column label="Date" width="125" prop="publishTime" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi"></el-table-column>
                        <el-table-column label="Title" width="685" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" @click="setid(scope.row)" style="width: 665px" class="tbl_company line_1">
                                    {{ scope.row.title }}
                                </div>
                                <div v-show="!showTranslate" @click="setid(scope.row)" style="width: 665px" class="tbl_company line_1">
                                    {{ scope.row.titleEn }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Source" width="258" :prop="showTranslate == true ? 'source' : 'sourceEn'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                        <el-table-column label="Impact" width="80" :prop="showTranslate == false ? 'status_en' : 'status_en'" align="center" :resizable="false">
                            <template slot-scope="scope">
                                <div class="flex_center">
                                    <div v-if="$route.query.companyCountry == 'CN'">
                                        <div v-if="scope.row.emotionLabel == '3'" class="impact_status red"><img src="../../assets/img/detail/tbl_ic2.png" /></div>
                                        <div v-else class="impact_status green"><img src="../../assets/img/detail/tbl_ic1.png" /></div>
                                    </div>
                                    <div v-else class="impact_status">
                                        <img src="@/assets/img/henggang.png" />
                                    </div>
                                    <!-- </el-tooltip> -->
                                </div>
                            </template>
                        </el-table-column>

                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left"><noDate /></div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt line_2 computer_right_cont_box">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                    </div>
                </div>
            </div>
            <!-- 手机端 -->
            <div class="phone_page_bg">
                      <Phonelist :total="page.total" :page="page" :showTranslate="showTranslate" :parentTable="parentTable" :searchName="searchName" :powerResult="powerResult">:</Phonelist>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                    </div>
            </div>
           
        </div>
        <!-- 收藏弹框 -->
        <!-- <Collection :addList.sync="addList" :fromPage="fromPage" @childCloseDialog="closeDialog1(arguments)" :addPop="addPopIndex" :collecObj="collecObj"></Collection> -->
        <!-- 高级检索弹框 -->
        <Advanced :addList.sync="parentTable" :seachName="searchName" :searchCode="searchCode" :fromPage="fromPage" @childCloseDialog2="closeDialog2(arguments)" :addPop="addPopIndex2"></Advanced>
        <Deepassment :showReport="showReportIndex" @closeReportDialog="closeDialog3($event)"></Deepassment>
        <!-- 高级检索意见 -->
        <!-- phone _apply afiilter -->
        <el-drawer title="" :visible.sync="drawer" size="300px" :with-header="false" direction="ltr">
            <transition name="fade" :duration="500">
                <div class="phoneappl_filters" v-loading="loading">
                    <div class="left_menu">
                        <button class="btn_Retr" @click="RetrievalResult">
                            <img src="../../assets/img/search/btn_ic1.png" />
                            Apply Filters
                        </button>

                        <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()">
                            <el-collapse-item name="0">
                                <template slot="title">
                                    Sort
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader ref="Sort" filterable clearable placeholder="By Date" :key="cascaderKey" v-model="companyTypeCheckList" @change="menuCheck('')" :options="newslsits" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <span class="casca_lab" style="width: 180px">
                                                <span ref="sty">{{ data.valueEn }}</span>
                                            </span>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Date" name="1">
                                <template slot="title">
                                    Date
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0].power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader ref="Date" filterable clearable placeholder="Select Date" :filter-method="filterCasindst" v-model="timePeriodCheckList" @change="menuCheck('')" :options="timePeriodList" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <span class="casca_lab">{{ data.valueEn }}</span>
                                            <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Source" name="2" v-if="sourcelist">
                                <template slot="title">
                                    Source
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader filterable ref="Country" clearable placeholder="Select Source" :filter-method="filterCasindst" v-model="companyStatusCheckList" :options="sourcelist" @change="menuCheck('')" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                                <span class="casca_lab">{{ data.valueEn }}</span>
                                            </el-tooltip>
                                            <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>

                            <el-collapse-item title="Industry" name="3" v-if="companyLeveList?.length > 0">
                                <template slot="title">
                                    Industry
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader ref="Sort" filterable clearable placeholder="Select Industry" :filter-method="filterCasindst" :key="cascaderKey" v-model="companyLeveCheckList" @change="menuCheck('')" :options="companyLeveList" :props="optionsProps" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                                <span class="casca_lab">{{ data.valueEn }}</span>
                                            </el-tooltip>
                                            <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Subjects" name="4">
                                <template slot="title">
                                    Subjects
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader filterable ref="Industry" clearable :filter-method="filterCasindst" v-model="listedCompanyCheckList" :key="cascaderKey" @change="menuCheck('')" :options="listedCompanyList" placeholder="Select Subjects" :props="optionsProps" popper-class="cas_loc_box ">
                                        <template slot-scope="{ data }">
                                            <span class="casca_lab">{{ data.valueEn }}</span>
                                            <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company" name="5">
                                <template slot="title">
                                    Company
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader clearable filterable v-model="companySizeCheckList" :filter-method="filterCasindst" :options="companyNameList" @change="menuCheck('')" placeholder="Select Company" collapse-tags :props="optionsProps" popper-class="cas_loc_box ">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                                <span class="casca_lab">{{ data.valueEn }}</span>
                                            </el-tooltip>
                                            <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                                <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="News Type" name="6">
                                <template slot="title">
                                    News Type
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <el-cascader clearable filterable v-model="companyNewsCheckList" :options="companyNewsList" @change="menuCheck('')" placeholder="Select News Type" collapse-tags :props="optionsProps" popper-class="cas_loc_box ">
                                        <template slot-scope="{ data }">
                                            <span class="casca_lab">{{ data.valueEn }}</span>
                                            <span class="casca_count">{{ data.dateCount | FilterNum }}</span>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </transition>
        </el-drawer>
    </div>
</template>
<script>
import bus from '@/components/bus';
import { searchNews, selectGroup, searchriskAndComp, searchNewsConditions, getPdfLink } from '@/api/searchApi.js';
import { getoptions, getCompanyDetailMenuList, getIsCollect } from '../../api/companyApi';
import Page from '@/components/page';
import noDate from '@/components/newstablenodata';
import { mixins1 } from '@/mixins/index';
// import Collection from '@/components/collection';
import Advanced from '@/components/newsadvanced';
import Deepassment from '@/components/deepassment.vue';
import crypto from '@/utils/crypto';
import Phonelist from './pohnelist.vue';

export default {
    mixins: [mixins1],
    components: { noDate, Page, Advanced, Deepassment, Phonelist },
    data() {
        return {
            purchaseDialog: false,
            loading: true,
            addPopIndex2: false,
            value: 'Entity',
            zhicai: 'Sanction',
            zhicaiTable: [],
            zhicaiTable1: [],
            searchinput: '',
            searchoptions: [
                {
                    value: 'Entity',
                    label: 'Company',
                },
                {
                    value: 'Person',
                    label: 'Person',
                },
            ],
            isShowTooltip: false,
            isActive: false,
            sactio: false,
            searchCode: '',
            countryxuanze: '',
            loadig: true,
            showReportIndex: false,
            arrProvTag: [],
            crylist: [],
            //openMenu:['Country','Location'],

            //token:'',
            refresh: 0,
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page2: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            pageNo: 1,
            pageSize: 10,
            searchName: '',
            cascaderKey: 0,
            showTranslate: false,
            expands: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            filterName: '',
            filterCountry: '',
            checkList: [],
            checkLocList: [],
            // location: '',
            // locList: [], //地区
            //表
            parentTable: [],
            childTable: [],
            addDialog: false, //添加列表弹框
            index: -1,
            addList: [],
            addPopIndex: false,
            fromPage: 'company',
            collecObj: {},
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            companyList: [],
            companyTblList: [],
            newslsits: [],
            companyNameList: [],
            sourcelist: [],
            author: [],
            countryList: [],
            company: [
                {
                    value: '3',
                    label: 'Apple',
                },
                {
                    value: '4',
                    label: 'WALMART INC.',
                },
                {
                    value: '2',
                    label: 'DOLLAR GENERAL CORPORATION',
                },
                {
                    value: '1',
                    label: 'M2BIO SCIENCES INC',
                },
            ],
            branchflag: '',
            openMenu: [],

            // geographicalLocation:[],
            countryPreList: [],
            // countryList: [],
            countryCheckList: [],
            countryTag: [],
            countryCheck: '',

            industryPreList: [],
            industryList: [],
            industryCheckList: [],
            industryTag: [],
            industryCheck: '',

            companyTypeList: [],
            companyTypeList1: [],
            companyTypeCheckList: [],
            companyTypeTag: [],
            companyTypeCheck: '',

            companySizeList: [],
            companySizeCheckList: [],
            companySizeTag: [],
            companySizeCheck: '',

            companyNewsList: [],
            companyNewsCheckList: [],
            companyNewsTag: [],
            companyNewsCheck: '',

            companyLeveList: [],
            companyLeveCheckList: [],
            companyLeveTag: [],
            companyLeveCheck: '',

            companyStatusList: [],
            companyStatusCheckList: [],
            companyStatusTag: [],
            companyStatusCheck: '',

            listedCompanyList: [],
            listedCompanyCheckList: [],
            listedCompanyTag: [],
            listedCompanyCheck: '',

            timePeriodList: [],
            timePeriodCheckList: [],
            timeTag: [],
            timePeriodCheck: '',
            province: [],
            city: [],
            keywords: '',
            // 下载
            title: 'Company',
            json_fields: {
                'Company Name': 'company_name_en',
                Province: 'province_en',
                City: 'city_en',
                Industry: 'isic_en',
                'Inc.Yr.': 'incorporation_year',
                'Company Status': 'status_en',
                'Company Type': 'company_type_en',
                'Company Size': 'scale_en',
            },
            DetailsForm: [],
            //权限接口获取
            powerResult: { list: [{ power: '' }] },
            token: localStorage.getItem('token'),
            optionsProps: {
                label: 'valueEn', //名称展示
                value: 'code', //值展示
                // expandTrigger: 'click', //次级菜单的展开方式
            },
            // locOptionProps: {
            //     label: 'companyCountry.name',  //名称展示
            //     value: 'companyCountry.value',            //值展示
            //     expandTrigger:'click',   //次级菜单的展开方式
            //     children:'companyProvince'
            // },
            loadPageNum: 0,

            cascInptCountry: '',
            showInptCountry: true,

            cascInptIndustry: '',
            showInptIndustry: true,
            companystr: '',
            drawer:false
        };
    },
    created() {
        if (!this.token) this.getPower();
        // this.getConditions();
    },
    mounted() {
        // this.getConditions();
        document.querySelectorAll('.el-cascader-node__label').forEach(el => {
            el.onclick = function () {
                if (this.previousElementSibling) this.previousElementSibling.click();
            };
        });
    },
    beforeDestroy() {
        this.$off('closeReportDialog');
    },
    updated() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.openMenu = ['2', '3', '4', '5', '6', '7', '8'];
                    this.getConditions();
                    this.getPower();
                }
            },
            immediate: true,
        },
        countryList(val) {
            this.cascaderKey++;
        },
        industryList(val) {
            this.cascaderKey++;
        },
    },
    methods: {
              applefilters() {
            this.isFadedIn = !this.isFadedIn;
            if (window.innerWidth < 821) {
                this.drawer = !this.drawer;
            }
        },
        checktype() {
            if (this.searchinput != '') {
                this.zhicaiseach();
            }
        },
        zhicaiseach() {
            searchriskAndComp({
                page: this.page2.pageNo,
                pageSize: this.page2.pageSize,
                keyword: this.searchinput,
                type: this.value,
            }).then(res => {
                if (res && res.code) {
                    if (this.searchinput == '') {
                        this.zhicaiTable1 = [];
                        this.page2.total = this.zhicaiTable1.length;
                    } else {
                        this.page2.total = res.data.total;
                        this.zhicaiTable1 = res.data.rows;
                    }
                } else {
                    this.zhicaiTable1 = [];
                    this.page2.total = this.zhicaiTable1.length;
                }
            });
        },
        setiddetail(row) {
            let routeData = this.$router.resolve({
                path: '/search/newsanction?' + '&type=' + row.attributes.type + '&id=' + row.id + '&gender=' + row.attributes.gender + '&subsidiary=' + row.attributes.is_subsidiary + '&name=' + row.attributes.primary_name,
            });

            window.open(routeData.href, '_blank');
        },
        zhankai() {
            let hasPower = this.powerResult.list[0].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },
        filterCasindst(node, val) {
            if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
                return true;
            }
        },
        gotocountry1(row) {
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //   if(!row.countryCode.includes(';')){
            //   }
        },

        tabshow() {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },

        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译】和【详情】
            const token = localStorage.getItem('token');

            if (!token || token == null) {
                if (!(e.target?._prevClass === 'no_login')) {
                    // bus.$emit('headLogFlg', true); //是否显示登录
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },

        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            let params = 'type=6';
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data[0];
                // this.token = sessionStorage.getItem('token');
                this.searchCompanyByName();
                // this.getCollData();
            }
        },

        setid(row) {
            let power = this.powerResult.list[5].power;
            if (power) {
                if (row.link == undefined) {
                    getPdfLink({ articleRef: row.id }).then(res => {
                        let res1 = JSON.parse(crypto.decrypt(res));

                        if (res1.data.includes('http')) {
                            let routeData = res1.data;
                            window.open(routeData, '_blank');
                        } else {
                            let routeData = 'http://' + res1.data;
                            window.open(routeData, '_blank');
                        }
                        // let routeData = res1.data;
                        // window.open(routeData, '_blank');
                    });
                }
                if (row.link.includes('http')) {
                    let routeData = row.link;
                    window.open(routeData, '_blank');
                } else {
                    let routeData = 'http://' + row.link;
                    window.open(routeData, '_blank');
                }
                // let routeData = row.url;
                // window.open(routeData, '_blank');
            } else {
                this.message();
            }
        },
        //获取左边菜单
        async getConditions() {
            this.loadig = true;
            const searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            const companyCountry = this.countryCheck ? this.countryCheck : this.$route.query.companyCountrydqs;
            this.keywords = this.$route.query.keword;
            const params = {
                countryCode: companyCountry,
                title: searchName,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                companyNameEn: this.companySizeCheck,
                source: this.companyStatusCheck,
                sortCode: this.companyTypeCheck,
                subjectCodeList: this.listedCompanyCheck,
                publishTime: this.timePeriodCheck,
                sourceGenre: this.companyNewsCheck,
                // deDuplicationLevelCode:this.companyLeveCheck,
                industry: this.companyLeveCheck,
            };
            searchNewsConditions(params).then(res1 => {
                let res = JSON.parse(crypto.decrypt(res1));
                    console.log(res);
                if (res && res.code) {
                
                    this.companyNameList = res.data.companyNameList;
                    this.listedCompanyList = res.data.subjectList;
                    // this.industryList=res.data.industryList
                    this.timePeriodList = res.data.timeList;
                    this.sourcelist = res.data.sourceList;
                    this.newslsits = res.data.sortList;
                    this.companyNewsList = res.data.typeList;
                    this.companyLeveList = res.data.industryList;
                    this.loadig = false;
                } else {
                    this.loadig = false;
                }
            });
        },

        //国家级联搜索不区分大小写
        inptCountrySearch() {
            this.$nextTick(() => {
                this.$refs.Country.dropDownVisible = true;
            });
            let queryString = this.cascInptCountry;
            var countryList = this.countryPreList;
            // this.$refs.Country.dropDownVisible=true
            var results = queryString ? countryList.filter(this.createFilter(queryString)) : countryList;
            this.countryList = results;
            // this.$refs.Country.dropDownVisible=true
        },
        createFilter(queryString) {
            return countryList => {
                return countryList.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },

        //二次查询
        RetrievalResult() {
            let power = this.powerResult.list[0].power;
            if (power) {
                this.searchCompanyByName();
                this.getConditions();
            } else {
                this.message();
            }
        },
        //搜索公司
        async searchCompanyByName() {
            this.loading = true;
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            // const searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountrydqs;
            this.keywords = this.$route.query.keword;
            this.industryCheck = this.industryCheck == undefined ? '' : this.industryCheck;
            let params = {
                title: this.searchName,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                countryCode: this.searchCode,
                companyNameEn: this.companySizeCheck,
                source: this.companyStatusCheck,
                sortCode: this.companyTypeCheck,
                subjectCodeList: this.listedCompanyCheck,
                publishTime: this.timePeriodCheck,
                sourceGenre: this.companyNewsCheck,
                // deDuplicationLevelCode:this.companyLeveCheck,
                industry: this.companyLeveCheck,
            };
            let data = await searchNews(params);
            let res = JSON.parse(crypto.decrypt(data));
            console.log(`res`, res);
            this.parentTable = [];
            if (res && res.code) {
                if (res.ret == '403') {
                    this.addPopIndex2 = true;
                }
                this.loading = false;
                this.page.total = res.data.total;

                this.sactio = false;
                let list = (this.parentTable = res.data.rows);
                //查找本地图片
                list = list.map((item, index) => {
                    let countryImg = this.getCountryImgUrl(item.company_country);
                    let flag = require('../../../static' + countryImg);
                    this.parentTable[index].flag = flag;
                    this.refresh++;
                });
            } else {
                this.loading = false;
                // this.addPopIndex2=true
                this.parentTable = [];
            }
        },

        //菜单checkbox选择
        menuCheck(val) {
            if (val === 'country') {
                if (val === 'country') {
                    let arrCountry = [];
                    let arrProv = [];
                    let arrCountryTag = [];
                    this.countryCheckList.forEach(item => {
                        if (!arrCountry.includes(item)) {
                            this.countryList.forEach(ite => {
                                if (ite.value == item) {
                                    arrCountryTag.push(ite.label);
                                }
                            });

                            arrCountry.push(item);
                        }
                        // arrProv.push(item[1]);
                    });
                    // this.countryCheck = arrCountry.join('@@');
                    // this.province = arrProv.join('@@');
                    this.countryTag = arrCountryTag;
                    this.countryCheck = arrCountry;
                    this.province = arrProv;
                }
            } else {
                if (val === 'Industry') {
                    // if (val === 'Industry') {
                    let arr = [];
                    this.industryCheckList.forEach(item => {
                        this.industryList.forEach(it => {
                            if (it.value == item) {
                                arr.push(it);
                            }
                        });
                    });

                    this.industryTag = arr;
                    // this.industryCheck = this.industryCheckList.join('@@');
                    this.industryCheck = this.industryCheckList;
                    // }

                    //显示隐藏行业级联选择器的输入框
                }
                // if (val === 'companyType') {
                let arr = [];
                this.companyTypeCheckList.forEach(item => {
                    this.newslsits.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                        }
                    });
                });
                this.companyTypeTag = arr;
                let arrlist = [];
                arr.map(item => {
                    arrlist.push(item.code);
                });

                this.companyTypeCheck = arrlist.join('@@');
                // }
                // if (val === 'companySize') {
                //
                let arr1 = [];
                this.companySizeCheckList.forEach(item => {
                    this.companyNameList.forEach(it => {
                        if (it.code == item) {
                            arr1.push(it);
                        }
                    });
                });
                let arrlist1 = [];
                arr1.map(item => {
                    arrlist1.push(item.code);
                });
                this.companySizeTag = arr1;
                this.companySizeCheck = arrlist1.join('@@');
                // this.companySizeCheck = this.companySizeCheckList.join('@@');
                // }
                // if (val === 'listedCompany') {
                // company 公司筛选
                let arr2 = [];
                this.listedCompanyCheckList.forEach(item => {
                    this.listedCompanyList.forEach(it => {
                        if (it.code == item) {
                            arr2.push(it);
                        }
                    });
                });
                let arrlist2 = [];
                arr2.map(item => {
                    arrlist2.push(item.code);
                });
                this.listedCompanyTag = arr2;
                this.listedCompanyCheck = arrlist2.join('@@');
                // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
                // }
                // if (val === 'companyStatus') {
                let arr3 = [];
                this.companyStatusCheckList.forEach(item => {
                    this.sourcelist.forEach(it => {
                        if (it.code == item) {
                            arr3.push(it);
                        }
                    });
                });
                let arrlist3 = [];
                arr3.map(item => {
                    arrlist3.push(item.code);
                });
                this.companyStatusTag = arr3;
                this.companyStatusCheck = arrlist3.join('@@');
                // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
                // }
                // if (val === 'timePeriod') {
                let arr4 = [];
                this.timePeriodCheckList.forEach(item => {
                    this.timePeriodList.forEach(it => {
                        if (it.code == item) {
                            arr4.push(it);
                        }
                    });
                });
                let arrlist4 = [];
                arr4.map(item => {
                    arrlist4.push(item.code);
                });
                this.timeTag = arr4;
                this.timePeriodCheck = arrlist4.join('@@');
                this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                // }

                let arr5 = [];
                this.companyNewsCheckList.forEach(item => {
                    this.companyNewsList.forEach(it => {
                        if (it.code == item) {
                            arr5.push(it);
                        }
                    });
                });
                let arrlist5 = [];
                arr5.map(item => {
                    arrlist5.push(item.code);
                });
                this.companyNewsTag = arr5;
                this.companyNewsCheck = arrlist5.join('@@');
                // this.companyNewsCheck = this.companyNewsCheckList.join('@@');
                // }

                let arr6 = [];
                this.companyLeveCheckList.forEach(item => {
                    this.companyLeveList.forEach(it => {
                        if (it.code == item) {
                            arr6.push(it);
                        }
                    });
                });
                let arrlist6 = [];
                arr6.map(item => {
                    arrlist6.push(item.code);
                });
                this.companyLeveTag = arr6;
                this.companyLeveCheck = arrlist6.join('@@');

                this.getConditions();
            }
        },
        //清除标签
        handleClose(tag, name) {
            if (name === 'countryTag') {
                this.countryTag.splice(this.countryTag.indexOf(tag), 1);
                let tagcountry = '';
                this.countryCheckList.forEach((item, index) => {
                    this.countryList.forEach(ite => {
                        if (ite.label == tag) {
                            tagcountry = ite.value;
                        }
                    });

                    if (item.includes(tagcountry)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });

                if (this.countryTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                this.countryCheck = this.countryTag.join('@@');
            }
            if (name === 'arrProvTag') {
                this.arrProvTag.splice(this.arrProvTag.indexOf(tag), 1);
                this.countryCheckList.forEach((item, index) => {
                    if (item.includes(tag)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });

                if (this.arrProvTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                this.province = this.arrProvTag.join('@@');
                // this.countryxuanze=this.arrProvTag.join('')
            }
            if (name === 'industryTag') {
                this.industryTag.splice(this.industryTag.indexOf(tag), 1);
                this.industryCheckList = [];
                this.industryCheck = '';
                //显示隐藏行业级联选择器的输入框
                if (this.industryCheckList.length > 0) {
                    this.showInptIndustry = false;
                    this.cascInptIndustry = '';
                } else {
                    this.showInptIndustry = true;
                }
            }
            if (name === 'companyTypeTag') {
                this.companyTypeTag.splice(this.companyTypeTag.indexOf(tag), 1);
                this.companyTypeCheckList.splice(this.companyTypeCheckList.indexOf(tag.code), 1);
                let arr1 = [];
                this.companyTypeTag.map(item => {
                    arr1.push(item.value);
                });
                this.companyTypeCheck = arr1.join('@@');
                // this.companyTypeCheck = this.companyTypeCheckList.join('@@');
            }
            if (name === 'companySizeTag') {
                this.companySizeTag.splice(this.companySizeTag.indexOf(tag), 1);
                this.companySizeCheckList.splice(this.companySizeCheckList.indexOf(tag.code), 1);
                // this.companySizeCheck = this.companySizeCheckList.join('@@');
                let arr2 = [];
                this.companySizeTag.map(item => {
                    arr2.push(item.value);
                });
                this.companySizeCheck = arr2.join('@@');
            }
            if (name === 'companyStatusTag') {
                this.companyStatusTag.splice(this.companyStatusTag.indexOf(tag), 1);
                this.companyStatusCheckList.splice(this.companyStatusCheckList.indexOf(tag.code), 1);
                // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
                let arr3 = [];
                this.companyStatusTag.map(item => {
                    arr3.push(item.value);
                });
                this.companyStatusCheck = arr3.join('@@');
            }
            if (name === 'listedCompanyTag') {
                this.listedCompanyTag.splice(this.listedCompanyTag.indexOf(tag), 1);
                this.listedCompanyCheckList.splice(this.listedCompanyCheckList.indexOf(tag.value), 1);
                // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
                let arr4 = [];
                this.listedCompanyTag.map(item => {
                    arr4.push(item.value);
                });
                this.listedCompanyCheck = arr4.join('@@');
            }
            if (name === 'timeTag') {
                this.timeTag.splice(this.timeTag.indexOf(tag), 1);

                this.timePeriodCheckList.splice(this.timePeriodCheckList.indexOf(tag.code), 1);
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                let arr5 = [];
                this.timeTag.map(item => {
                    arr5.push(item.value);
                });
                this.timePeriodCheck = arr5.join('@@');
            }
            if (name === 'NewTag') {
                this.companyNewsTag.splice(this.companyNewsTag.indexOf(tag), 1);

                this.companyNewsCheckList.splice(this.companyNewsCheckList.indexOf(tag.code), 1);
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                let arr5 = [];
                this.companyNewsTag.map(item => {
                    arr5.push(item.value);
                });
                this.companyNewsCheck = arr5.join('@@');
            }
            if (name === 'LeveTag') {
                this.companyLeveTag.splice(this.companyLeveTag.indexOf(tag), 1);

                this.companyLeveCheckList.splice(this.companyLeveCheckList.indexOf(tag.code), 1);
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                let arr5 = [];
                this.companyLeveTag.map(item => {
                    arr5.push(item.value);
                });
                this.companyLeveCheck = arr5.join('@@');
            }
            // companyLeveList: [],
            // companyLeveCheckList: [],
            // companyLeveTag: [],
            // companyLeveCheck: '',
            this.getConditions();
        },
        //清除所有标签
        clearAllTags() {
            this.countryTag = [];
            this.province = '';
            this.arrProvTag = [];
            this.countryCheck = '';
            this.industryTag = [];
            this.companyTypeTag = [];
            this.companySizeTag = [];
            this.companyStatusTag = [];
            this.listedCompanyTag = [];
            this.timeTag = [];
            (this.companyNewsList = []), (this.companyNewsCheckList = []), (this.companyNewsTag = []), (this.companyNewsCheck = ''), (this.companyLeveList = []), (this.companyLeveCheckList = []), (this.companyLeveTag = []), (this.companyLeveCheck = ''), (this.countryCheckList = []);
            this.industryCheckList = [];
            this.companyTypeCheckList = [];
            this.companySizeCheckList = [];
            this.companyStatusCheckList = [];
            this.listedCompanyCheckList = [];
            this.timePeriodCheckList = [];
            this.newslsits = [];
            this.author = [];
            this.companyNameList = [];
            this.sourcelist = [];
            this.industryCheck = '';
            this.companyTypeCheck = '';
            this.companySizeCheck = '';
            this.companyStatusCheck = '';
            this.listedCompanyCheck = '';
            this.timePeriodCheck = '';
            this.showInptCountry = true;
            this.getConditions();
        },

        onPageChange2(pageNo) {
            // if (power) {
            this.page2.pageNo = pageNo;
            this.zhicaiseach();
        },
        currtentPageChange2(pageSize) {
            this.page2.pageSize = pageSize;
            this.zhicaiseach();
        },
        //分页
        onPageChange(pageNo) {
            let power = this.powerResult.list[4].power;
            if (power) {
                this.page.pageNo = pageNo;
                this.searchCompanyByName();
            } else {
                this.message();
            }
        },
        currtentPageChange(pageSize) {
            let power = this.powerResult.list[4].power;
            if (power) {
                this.page.pageSize = pageSize;
                this.searchCompanyByName();
            } else {
                this.message();
            }
        },

        closeDialog1(value) {
            this.addPopIndex = value[0];
            this.collecObj.isFavorite = value[1];
        },
        // 新增高级检索
        closeDialog2(value) {
            this.addPopIndex2 = value[0];
        },
        closeDialog3(value) {
            this.showReportIndex = value;
        },

        //导出按钮
        exportResult() {
            let power = this.powerResult.list[3].power;
            let deductionPoint = true;
            if (deductionPoint) {
                this.purchaseDialog = true;
            } else {
                this.message();
            }
        },
    },
};
</script>
<style scoped>
.table_box /deep/ .el-table th > .cell {
    padding-right: 0;
    padding-left: 0;
}
.add_list {
    margin-bottom: 20px;
}
.add_list /deep/ .el-radio-button__inner {
    display: flex;
    height: 62px;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}
.add_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #1290c9;
    color: #ffffff;
    box-shadow: none;
}

.add_list /deep/ .el-radio-button__inner:hover {
    background: #e7eaed;
}

.is-active .add_des_txt,
.is-active .add_des_title {
    color: #ffffff;
}

.add_img {
    height: 42px;
    flex: none;
}

.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}

.add_des_title {
    width: 370px;
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
    text-align: left;
}

.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
    text-align: left;
}

.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}

.btn_create {
    width: 180px;
}

.btn_return {
    width: 140px;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}
/* .table_box .el-table th>.cell :first-child{
    padding-left: 30px;
} */

.tree_table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 40px !important;
}
.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}

.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}
.yangsi {
    padding-left: 30px !important;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}
.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    float: left;
    max-width: 888px;
}
.condition_labb /deep/ .el-tag {
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.indystry .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100% !important;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
/* .table_box /deep/ .el-table__empty-block{
    justify-content: flex-start !important;
    display: block;
    text-align: center;
} */
.el-table td,
.el-table /deep/ th.is-leaf {
    border-bottom: 1px solid #e7eaed;
    border-right: none;
    padding-left: 10px;
}
.table_box /deep/ .el-table .cell {
    padding-right: 0px;
}
.title-left {
    display: flex;
    font-size: 16px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #022955;
    margin-right: 20px;
}
.btn_Retr2 {
    margin-top: 20px;
    width: 260px;
    height: 40px;
    line-height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn_Retr2_title {
    width: 50%;
    background: #1290c9;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    border-radius: 12px;
    cursor: pointer;
}
.newslsit {
    margin: 15px 0px;
}
.newslsit /deep/ .el-checkbox__input {
    display: none;
}
.impact_status {
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 50%;
}
.impact_status img {
    width: 14px;
    height: 14px;
    margin-top: 6px;
}
.impact_status.green {
    background: #55b419;
}
.impact_status.hui {
    background: #ccc;
}
.impact_status.blue {
    background: #1290c9;
}
.impact_status.red {
    background: #ff7600;
}
.cas_loc_box /deep/ .el-cascader-node__label,
.cas_loc_box .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 140px;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.cas_loc_box1 .casca_lab {
    width: 114px;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.table_box_two .no_date_box {
    width: 100%;
    /* margin: 5vh auto 20vh; */
    padding: 20px;
}
.table_box_two .no_img {
    width: 250px;
    height: 197px;
    margin: 0 auto;
    margin-bottom: 4px;
}
.table_box_two .no_img img {
    width: 100%;
    height: auto;
}
.table_box_two .des_one {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
    font-family: 'Arial Bold';
    color: #1290c9;
    margin-bottom: 10px;
}
.table_box_two .des_two {
    font-size: 12px;
    color: #022955;
    line-height: 24px;
}
.seach-title {
    font-size: 16px;
    text-align: center;
    margin-right: 80px;
    color: #022955 !important;
}
.seach-condition {
    width: 60%;
    /* margin: auto;
    margin-top: 20px; */
    position: relative;
}
.seach-condition /deep/ .el-select .el-input {
    width: 130px;
}
.seach-condition .shuxian {
    position: absolute;
    left: 130px;
    top: 13px;
    height: 13px;
    width: 1px;
    background-color: #ccc;
    z-index: 1;
}
.seach-condition /deep/ .el-input-group__prepend {
    background-color: #fff;
    border-radius: 14px 0 0 14px;
}
.seach-condition /deep/ .el-select .el-input .el-input__inner {
    /* background-color: #fff; */
    /* border-radius: 0; */
    border: none;
}
.seach-condition /deep/.el-select .el-icon-arrow-up:before {
    /* border-left: 1px solid #ccc; */
    /* padding-left: 5px; */
    color: #ccc !important;
}
.seach-condition /deep/ .el-input__inner {
    border-radius: 0 14px 14px 0;
    background-color: #fff;
    border-left: none;
    color: #022955 !important;
}

.seach-condition /deep/ .el-input__inner:focus {
    border-color: #ccc;
}
.seach-condition .seach-text {
    line-height: 30px;
    font-size: 14px;
}
.seaction-table {
    margin-top: 15px;
}
.name-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.head-title {
    color: #022955;
    font-size: 16px;
}
.name-information {
    width: 800px;
    display: flex;
    justify-content: space-around;
}
.information-box {
    font-size: 14px;
}
.information-box-text {
    font-size: 14px;
    color: #022955;
}
.information-box-text1 {
    font-size: 14px;
    color: #1290c9;
    line-height: 25px;
}
.name-overview {
    display: flex;
    justify-content: space-between;
}
.name-overview-box {
    width: 50%;
    padding-right: 20px;
    /* font-size: 15px; */
}
.name-overview-box-title {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.seach-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.name-overview-box-text {
    padding: 20px;
}
.linheight {
    line-height: 20px;
}
.hedr-icon-ydong {
    position: absolute;
    left: 215px;
    font-weight: 600;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}
.zhankai {
    display: none;
}
@media (max-width: 821px) {
    .phone_right_cont_box {
        display: block !important;
    }
    .computer_right_cont_box {
        display: none;
    }
        .zhankai {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1290c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zhankai img {
        height: 18px;
        width: 18px;
    }
    .phoneappl_filters .left_menu {
        display: block !important;
    }

}
</style>
